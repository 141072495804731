// scroll margings
@use 'sass:map';

// Hide mobile view 
// @media (max-width: 1023px) {
// 	.hidden-mobile {
// 	  display: none;
// 	}
//   }

// btn-type-default is a gradient button color class which use default for every button  
.btn-type-default {
  	border-style: none;
  	background-size: 200% auto;
    color: white;
    background-image: linear-gradient(to right,$secondary 0%,$primary 50%, $secondary 100%);
    transition: 0.5s;
    &:hover {
      background-position: right center;
	  cursor: pointer;
    } 
}

// add padding left for ITme logo in sidebar Brand
.aside-logo {
	margin-left: -30px;
}

//Landing page font size
.landing-h1-web {
	font-size: 54px;
}

.landing-h2-web {
	font-size: 44px;
}

.landing-h3-web {
	font-size: 24px;
}

.landing-h1-mobile {
	font-size: 40px;
}

.landing-h2-mobile {
	font-size: 32px;
}

.landing-h3-mobile {
	font-size: 20px;
}

.landing-h4 {
	font-size: 18px;
}

.landing-h5 {
	font-size: 16px;
}

.landing-h6 {
	font-size: 14px;
}

.dashboard-paragraph {
	font-size: 13px;
}

.section-width {
	min-height: 60vh;
	width: 99.4vw;
}

// Landing page filter background color
.filter-bg {
	background-color: $btn-color-dark;
}

// Landing page text color
.green-text {
	color: #A6FF74;
}

// Landing page section background color
.section-bg {
	// background-color: $light-dark;
}

// Landing page filter search icon background color
.filetr-search-bg {
	background-color: $light-dark;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

// ITP Landing page splider
.splider-mobile {
	top: 140px;
	position: absolute;
}

.splider-mobile-text-bg {
	background-color: rgba($light-dark, 0.7);
	position: absolute;
}

// View Detailed Candidate page border
.skills-right-border:not(:nth-child(3n + 3)) {
	border-right: 1px solid $gray-600;
}

.job-preference-right-border {
	border-right: 1px solid $gray-600;
}

// active gradient color for pagination
// add to the bootstrap component
.pagination-active-bg {
	background: linear-gradient(to left, $primary,$secondary); 
	border-style: none;
}


// card colors for view job detaile page card
.card-bg {
	background-color: $gray-300;
}

[theme='dark']:root {
	.card-bg {
		background-color: $light-dark;
	}
}

.btn-type-default:hover {
  color: $white;
}

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}


//Add a custom scrollbar
::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: #eee;
}

::-webkit-scrollbar-thumb {
	background-color: #ccc;
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

[theme='dark']:root {
	::-webkit-scrollbar-track {
		background-color: rgba(#333, 0.7);
	}

	::-webkit-scrollbar-thumb {
		background-color: rgba(#666, 0.4);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}
	
	:hover::-webkit-scrollbar-thumb {
		background-color: #666;
	}
}

// Change the date picke color in create job vacancy in dark mode.
[theme='dark']:root {
	::-webkit-calendar-picker-indicator {
		filter: invert(1);
	}
}

// Hover effect for cards
.card-hover {
    box-shadow: 0 6px 10px rgba(255,255,255,.08), 0 0 6px rgba(255,255,255,.05);
    transition: .3s transform cubic-bezier(.155,1.105,.295,1.12),.3s box-shadow,.3s -webkit-transform cubic-bezier(.155,1.105,.295,1.12);
	cursor: pointer;
	&:hover {
		transform: scale(1.05);
		box-shadow: 0 10px 20px rgba(255,255,255,.12), 0 4px 8px rgba(255,255,255,.06);
	}
}

// Hover effect for button
.button-hover:hover {
	animation: pulse 1s infinite;
	transition: .3s;
  }
  @keyframes pulse {
	0% {
	  transform: scale(1);
	}
	70% {
	  transform: scale(1.1);
	}
	  100% {
	  transform: scale(1);
	}
  }
  
  // Hover effect for text
.text-hover {
	&:hover {
		color: $primary !important;
	}
}

.text-hover:after {
  backface-visibility: hidden;
  border-bottom: 1px solid rgba($primary, 0);
  bottom: 0px;
  content: " ";
  display: block;
  margin: 0 auto;
  position: relative;
  transition: all 280ms ease-in-out;
  width: 0;
}

.text-hover:hover:after {
  backface-visibility: hidden;
  border-color: $primary;
  transition: width 350ms ease-in-out;
  width: 70%;
}

// Hover effect for footer text in landing page
.landing-footer-text-hover {
	&:hover {
		color: $primary !important;
		background-position: 50% 100%;
  		background-size: 75% 1px;
	}
}

.landing-footer-text-hover {
	display: inline-block;
  	background-image: linear-gradient($primary 0 0);
  	background-position: 50% 100%;
  	background-size: 0% 1px;
  	background-repeat: no-repeat;
  	transition: all 280ms ease-in-out;
}

// Candidate details pages font size 
.no-data-text {
	font-size: 12px;
	align-self: flex-end;
}

// Candidate details pages input background color
.input-background {
	background-color: $white;
	color: $gray-600;
}

[theme='dark']:root {
	.input-background {
		background-color: $light-dark;
		color: $gray-600;
		border: .5px solid $gray-800;
		border-radius: 3px;
	}
}

// Candidate details pages phone number input background color
.phone-number-dropdown {
	background-color: $white-smoke;
	width: "360px";
	font-family: $font-family-base !important;
	font-size: 13px;
}

[theme='dark']:root {
	.phone-number-dropdown {
		background-color: $light-dark !important;
		width: "360px"
	}
	
	// phone number select button overide color 
	.react-tel-input .flag-dropdown.open .selected-flag {
		background: transparent !important;
		
	}
		
	.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
		background: transparent !important;	
	}
}

// shared detailed vacancy page blur overlay class
.blur-overlay {
	-webkit-filter: blur(5px);
	-moz-filter: blur(5px);
	-o-filter: blur(5px);
	-ms-filter: blur(5px);
	filter: blur(5px);
  }

// detail view of IT professional's description read more button style change
.read-more-more-module_btn__33IaH:hover::after,
.read-more-more-module_btn__33IaH::after {
   display: none;
}

// max with for landing pages
.max_width {
	max-width: 1255.2px;
}

// aside bar mobile collapse
@media (max-width: 767.98px){
	.aside:not(.open):not(:hover) {
		left: calc(( 16rem + 0rem ) * -1) !important;
	}
}

// aside bar mobile collapse
@media (max-width: 767.98px){
	.app{
		overflow: hidden !important;
	}
}
// Recruiter dashboard chart height
.chart_height {
	height: 380px;
}

// 
.dropmenue-width{
	width: 3rem !important;
}

// slider active dot color
.slick-dots li.slick-active button:before {
	color: #fff;
}

// slider dot color
.slick-dots li button:before {
	color: #fff;
}

// chat input box
.react-input-emoji--container{
	color: #fff !important;
	background-color: #222323 !important;
}

// chat fixed subheader
.position-fixed {
	position: sticky;
}

// chat emoji input styles overried
.react-emoji {
	background-color: #232323;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.react-emoji .react-input-emoji--input {
    padding: 2px 2px 2px; 
}

// landing page header color override
.landing_header {
	background-color: $shade-dark !important;
}

.landing_mobile-header {
	background-color: $shade-dark !important;
}

// privacy policy 

// * {
// 	margin: 0;
// 	padding: 0;
// 	text-indent: 0;
// }

.pp-s1 {
	color: #21B04B;
	font-family: "Arial Black", sans-serif;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
}

pp-p {
	color: black;
	font-family: "Lucida Sans Unicode", sans-serif;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
	margin: 0pt;
}

.a,
pp-a {
	color: black;
	font-family: "Lucida Sans Unicode", sans-serif;
	font-style: normal;
	font-weight: normal;
	text-decoration: none;
}

.max_width_landing_header {
	max-width: 900px;
}

.tab_menu_width {
	max-width: 80px;
	padding-left: 10%;
	padding-right: 10%;
}